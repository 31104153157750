@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap);
@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Black.67d3d004.woff2) format('woff2'),
        url(/static/media/Montserrat-Black.7cff12a5.woff) format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Bold.ecfa764e.woff2) format('woff2'),
        url(/static/media/Montserrat-Bold.87be09dc.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-ExtraBold.8727ddb9.woff2) format('woff2'),
        url(/static/media/Montserrat-ExtraBold.c1a66070.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-ExtraLight.34e7a400.woff2) format('woff2'),
        url(/static/media/Montserrat-ExtraLight.88f22562.woff) format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-BlackItalic.201622df.woff2) format('woff2'),
        url(/static/media/Montserrat-BlackItalic.0f7f4751.woff) format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-ExtraBoldItalic.4007d242.woff2) format('woff2'),
        url(/static/media/Montserrat-ExtraBoldItalic.77a07952.woff) format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-BoldItalic.ff3f635d.woff2) format('woff2'),
        url(/static/media/Montserrat-BoldItalic.454ae2c7.woff) format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-SemiBoldItalic.8541765b.woff2) format('woff2'),
        url(/static/media/Montserrat-SemiBoldItalic.1dd40918.woff) format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-MediumItalic.19409701.woff2) format('woff2'),
        url(/static/media/Montserrat-MediumItalic.1b9eebc2.woff) format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Light.73d46b19.woff2) format('woff2'),
        url(/static/media/Montserrat-Light.c154e051.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-LightItalic.742d0029.woff2) format('woff2'),
        url(/static/media/Montserrat-LightItalic.32c03573.woff) format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-ExtraLightItalic.69c47c28.woff2) format('woff2'),
        url(/static/media/Montserrat-ExtraLightItalic.7f6071dc.woff) format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Medium.80941f1a.woff2) format('woff2'),
        url(/static/media/Montserrat-Medium.5d15323c.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-SemiBold.a7345293.woff2) format('woff2'),
        url(/static/media/Montserrat-SemiBold.e3ec793c.woff) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Italic.ca6d1e68.woff2) format('woff2'),
        url(/static/media/Montserrat-Italic.69a48d2f.woff) format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Regular.db685431.woff2) format('woff2'),
        url(/static/media/Montserrat-Regular.ad6d5536.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Thin.5fe5293c.woff2) format('woff2'),
        url(/static/media/Montserrat-Thin.87bf8f92.woff) format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-ThinItalic.8a47d539.woff2) format('woff2'),
        url(/static/media/Montserrat-ThinItalic.e6bf30bf.woff) format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Bold.84adee34.woff2) format('woff2'),
        url(/static/media/Roboto-Bold.71cc7f4d.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Italic.0c62151b.woff2) format('woff2'),
        url(/static/media/Roboto-Italic.55cca991.woff) format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-BlackItalic.78a835e0.woff2) format('woff2'),
        url(/static/media/Roboto-BlackItalic.449f9a04.woff) format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-BoldItalic.0b369e1e.woff2) format('woff2'),
        url(/static/media/Roboto-BoldItalic.86c4536d.woff) format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Black.a3c964df.woff2) format('woff2'),
        url(/static/media/Roboto-Black.f4e373e0.woff) format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Light.83332147.woff2) format('woff2'),
        url(/static/media/Roboto-Light.2c231254.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-ThinItalic.a4b35a9c.woff2) format('woff2'),
        url(/static/media/Roboto-ThinItalic.6d9da0ba.woff) format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Regular.6ebf208d.woff2) format('woff2'),
        url(/static/media/Roboto-Regular.60ef8b89.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Medium.b4bc083c.woff2) format('woff2'),
        url(/static/media/Roboto-Medium.cb4b0e51.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Thin.f45dc927.woff2) format('woff2'),
        url(/static/media/Roboto-Thin.ae3a803c.woff) format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-MediumItalic.2ee1e62f.woff2) format('woff2'),
        url(/static/media/Roboto-MediumItalic.aebc8065.woff) format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-LightItalic.74fda2d0.woff2) format('woff2'),
        url(/static/media/Roboto-LightItalic.b1901583.woff) format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-ExtraBold.2fcf9e5e.woff2) format('woff2'),
        url(/static/media/EudoxusSans-ExtraBold.87ebf43e.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-ExtraBold.2fcf9e5e.woff2) format('woff2'),
        url(/static/media/EudoxusSans-ExtraBold.87ebf43e.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Bold.16f817e3.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Bold.9e36cdea.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Bold.16f817e3.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Bold.9e36cdea.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Bold.16f817e3.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Bold.9e36cdea.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Medium.53ba53b3.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Medium.b8e22bfe.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-ExtraLight.dc79939f.woff2) format('woff2'),
        url(/static/media/EudoxusSans-ExtraLight.5d89ed74.woff) format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-ExtraBold.2fcf9e5e.woff2) format('woff2'),
        url(/static/media/EudoxusSans-ExtraBold.87ebf43e.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Light.32134ac2.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Light.a51a8ba5.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Regular.04682480.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Regular.2a42c9a4.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Light.32134ac2.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Light.a51a8ba5.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Light.32134ac2.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Light.a51a8ba5.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Regular.04682480.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Regular.2a42c9a4.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-ExtraLight.dc79939f.woff2) format('woff2'),
        url(/static/media/EudoxusSans-ExtraLight.5d89ed74.woff) format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Regular.04682480.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Regular.2a42c9a4.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Medium.53ba53b3.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Medium.b8e22bfe.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-ExtraLight.dc79939f.woff2) format('woff2'),
        url(/static/media/EudoxusSans-ExtraLight.5d89ed74.woff) format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Medium.53ba53b3.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Medium.b8e22bfe.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Inter';
    src: url(/static/media/Inter-Bold.f78076ce.woff2) format('woff2'),
        url(/static/media/Inter-Bold.2d3fbb36.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/static/media/Inter-Medium.6c6673e5.woff2) format('woff2'),
        url(/static/media/Inter-Medium.b67e1204.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/static/media/Inter-Light.ceeb8712.woff2) format('woff2'),
        url(/static/media/Inter-Light.a58325fc.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/static/media/Inter-ExtraBold.ea6fc194.woff2) format('woff2'),
        url(/static/media/Inter-ExtraBold.c505e603.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/static/media/Inter-ExtraLight.5bbb8a4d.woff2) format('woff2'),
        url(/static/media/Inter-ExtraLight.a2fb66b1.woff) format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/static/media/Inter-Black.f3e3958b.woff2) format('woff2'),
        url(/static/media/Inter-Black.4e1e0ecf.woff) format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/static/media/Inter-Regular.a0ff0396.woff2) format('woff2'),
        url(/static/media/Inter-Regular.f901385f.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/static/media/Inter-SemiBold.0eec2995.woff2) format('woff2'),
        url(/static/media/Inter-SemiBold.5d3fb944.woff) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/static/media/Inter-Thin.244b06ac.woff2) format('woff2'),
        url(/static/media/Inter-Thin.6601311f.woff) format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}


.slick-slider .slick-slide {
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.slick-slider .slick-slide:last-child {
  padding-right: 3px;
}

body::-webkit-scrollbar {
  width: 8px;
}
body::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
body::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 10px;
  border: none;
}
.css-14u9vth-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 8px !important;
  font-size: 15px !important;
  height: 46px !important;
}
.dateTime .MuiOutlinedInput-root {
  border-radius: 8px !important;
  flex-direction: row-reverse;
  height: 46px !important;
}
.css-wtw5vo-MuiInputBase-input-MuiOutlinedInput-input {
  height: 13px !important;
}
/* .MuiFormControl-root .MuiAutocomplete-inputRoot{
  height: 46px !important;
} */
.category .MuiOutlinedInput-root {
  height: auto !important;
}
.chip input {
  height: 13px !important;
  min-width: 70px !important;
}
.css-17jfbmq-MuiFormLabel-root-MuiInputLabel-root {
  top: -3px !important;
}

.css-wgcozo-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 8px !important;
}

.css-ee7kim-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 8px !important;
}
/* .MuiTable-root tbody tr .MuiTypography-root {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
} */
.MuiTable-root tbody tr .categoryList {
  -webkit-line-clamp: 2;
}
.partner-text {
  -webkit-line-clamp: 3 !important;
}
.tournament_settings_img {
  width: 36px;
  height: 36px;
  border-radius: 5px;
  object-fit: cover;
  margin-top: 5px;
}
.MuiFormControl-root .MuiSvgIcon-root {
  color: #1b3861;
}
.MuiTablePagination-input .MuiSvgIcon-root {
  color: #1b3861;
}
.MuiTableRow-root.Mui-selected,
.MuiTableRow-root.Mui-selected:hover {
  background-color: #fafafa;
}
.entry-row:nth-child(odd) {
  background: #fafafa;
  border-radius: 8px;
  border: 1px solid #edf1f6;
}
tr:first-child td:first-child {
  border-top-left-radius: 10px;
}
tr:first-child td:last-child {
  border-top-right-radius: 10px;
}

tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.MuiInputLabel-shrink {
  color: #1b3861;
  font-weight: 600;
}
.sidebar-toggler svg {
  width: 30px;
}
iframe {
  display: none !important;
}
.MuiTablePagination-root .MuiToolbar-root {
  padding-left: 0;
}
.MuiTablePagination-root .MuiButtonBase-root {
  padding: 5px;
}
.MuiTablePagination-root .MuiTablePagination-actions {
  margin-left: 5px;
}
.MuiTablePagination-root .MuiInputBase-root {
  margin-right: 15px;
  margin-left: 0;
}
.MuiContainer-root {
  max-width: 100% !important;
}
.MuiTab-root {
  color: #1c3962;
}
.draw_category fieldset {
  border: 0;
}
.draw_category select {
  padding: 5px 14px 5px 5px;
}
.sc-dcJsrY {
  color: #19366066 !important;
  background-color: transparent !important;
  border: 1px solid #efefef !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  font-family: 'Inter, sans-serif';
}
/* .gULjcf::after,::before{
  border-color: #EFEFEF !important;
} */

.jknkFS::after {
  border-color: #efefef !important;
}
.datePlaceholder .MuiOutlinedInput-root .MuiOutlinedInput-input::placeholder {
  color: red;
}
.css-r64qfz {
  outline: none !important;
}
.css-1lomthf {
  align-items: center;
}
.error-input::placeholder {
  color: red;
}
.Mui-disabled {
  cursor: pointer !important;
}
.MuiListItem-root .active .MuiButton-startIcon svg path {
  fill: #ffff;
}
.MuiListItem-root .active .MuiBox-root {
  color: #ff5733;
}
.MuiListItem-root .active {
  background-color: rgba(41, 171, 226, 0.04);
}
.chip .MuiFormControl-root .MuiSvgIcon-root {
  color: rgba(25, 54, 96, 0.26) !important;
}
/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.dropzoneText span {
  word-break: break-all;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.MuiPaper-root::-webkit-scrollbar {
  width: 8px;
}
.MuiPaper-root::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
.MuiPaper-root::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 10px;
  border: none;
}

/* For Print Table */
@media print {
  .ps {
    width: 100%;
    height: 100% !important;
    overflow: visible !important;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
  }
}

@page chapter {
  size: portrait;
  margin: 5px;
}

.jknkFS:nth-child(even)::before {
  border-top: 1px solid #eee !important;
}

.qualWinners:nth-child(even)::before {
  border-top: none !important;
}

.qualWinners:nth-child(odd):not(:last-child)::after {
  border-right: none !important;
  border-top: none !important;
  /* content: attr(data-content);
  min-width: 100px;
  width: max-content;
  height: 1px;
  background-color: #eee;
  left: calc(100% - 18px);
  display: flex;
  padding: 0px 10px;
  flex-flow: column-reverse;
  font-weight: bold;
  text-transform: uppercase; */
}

.qualWinners:nth-child(even)::after {
  border-bottom: none !important;
  border-right: none !important;
  /* content: attr(data-content);
  min-width: 100px;
  width: max-content;
  height: 1px;
  background-color: #eee;
  left: calc(100% - 18px);
  top: initial !important;
  display: flex;
  padding: 0px 10px;
  flex-flow: column-reverse;
  font-weight: bold;
  text-transform: uppercase; */
}

.qualWinnerBox::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 20px;
  height: 1px;
  background-color: #eee;
}

.MuiPickersDay-root.Mui-disabled {
  border: none !important;
}

@media (max-width: 992px) {
  .thirdPlaceMatchSeed {
    margin-left: auto !important;
    margin-right: auto !important;
    float: none !important;
  }
}

#nprogress .bar {
  background: #ff5733 !important;
}

#nprogress .spinner-icon {
  border-top-color: #ff5733 !important;
  border-left-color: #ff5733 !important;
}

.svg-current-color,
.svg-current-color path {
  fill: currentColor;
}

body::-webkit-scrollbar,
body *::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body::-webkit-scrollbar-thumb,
body *::-webkit-scrollbar-thumb {
  background-color: rgba(61, 61, 61, 0.5);
  border-radius: 4px;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
}

body::-webkit-scrollbar-thumb:hover,
body *::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.6);
  width: 12px !important;
  height: 12px !important;
}

body::-webkit-scrollbar-track,
body *::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

body::-webkit-scrollbar-corner,
body *::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* For draws */
@media (max-width: 768px) {
  /* Adjust the max-width value based on your target screen size */
  .odd > div > div > div:nth-child(2) > div > div:nth-child(2) {
    width: 94%;
    margin: 0 10px;
  }

  .even > div > div > div > div > div:nth-child(2) {
    width: 94%;
    margin: 0 10px;
  }
}

.highlighted > div > div:nth-child(1) > div {
  background-color: #fae5e5;
}

.jknkFS::after {
  border-color: #efefef !important;
  /* top: calc(50% - 12.5px) !important; */
}

/* .dWYBbN::after{
border-color: #efefef !important;

} */
.dWYBbN::before {
  border-color: #efefef !important;
  border-top: 1px solid #efefef !important;
}

.gCXEnU::after {
  border-color: #efefef !important;
}

.gCXEnU::before {
  border-color: #efefef !important;
}

.jknkFS::before {
  border-color: #efefef !important;
  /* top: calc(50% - 1.5px) !important; */
}


.no-print {
  @media print {
    display: none;
  }
}
.e-event {
  height: 50px; /* Adjust height as needed */
  line-height: 50px; /* Align text vertically */
}

.calendar-custom-wrapper {
  position: absolute;
  /* top: 48px !important; */
  z-index: 9999999 !important;
  /* height: 480px !important; */
  background: #fff;
}

.dashboard-date-picker .react-calendar {
  max-width: 320px;
  background: var(--navy-blue-color);
  box-shadow: 3px 4px 69px rgb(0 0 0 / 50%);
  border: none;
  border-radius: 8px;
  font-family: "Inter, sans-serif";
  padding: 28px 22px;
  width: auto;
}

.dashboard-date-picker .react-calendar {
  position: relative;
}

.dashboard-date-picker .react-calendar__navigation button {
  color: #ff5733;
  font-family: "Inter";
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  background: none;
  border: 0;
}
.dashboard-date-picker .react-calendar__navigation__label {
  color: #0a2540 !important;
}

.dashboard-date-picker .react-calendar__navigation button:disabled {
  background: var(--navy-blue-color);
}

.dashboard-date-picker .react-calendar__navigation button:first-child,
.dashboard-date-picker .react-calendar__navigation button:last-child {
  display: none;
}

.dashboard-date-picker .search-calendar-btn {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 24px;
  bottom: 24px;
}

.dashboard-date-picker .search-calendar-btn::before {
  background-color: var(--navy-blue-color);
}

.dashboard-date-picker .react-calendar__navigation button:last-child::before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  border-radius: 4px;
  background-color: var(--navy-blue-color);
  z-index: -1;
}

.dashboard-date-picker .react-calendar__navigation button:last-child::after {
  content: "";
  font-size: 16px;
  background: linear-gradient(to left, #00ffa3, #000) !important;
  -webkit-background-clip: text;
  color: transparent;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(3) {
  flex-grow: inherit !important;
  pointer-events: none !important;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(2),
.dashboard-date-picker .react-calendar__navigation button:nth-child(4) {
  padding: 0px;
  width: 24px;
  border-radius: 50%;
  min-width: unset;
  position: absolute;
  top: 40px;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(2) {
  right: 48px;
  padding-right: 2px;
  top: 30px;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(4) {
  right: 22px;
  padding-left: 2px;
  top: 30px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}

.react-calendar__navigation button:enabled:hover {
  background-color: #ff5733 !important;
  color: white;
}

.dashboard-date-picker .react-calendar__month-view__weekdays {
  color: var(--secondary-grey-color);
  font-family: "Inter, sans-serif";
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: capitalize;
  margin-top: 32px !important;
}

.dashboard-date-picker .react-calendar__month-view__weekdays abbr {
  text-decoration: none;
}

.dashboard-date-picker .react-calendar__tile {
  border: none;
  background: none;
  color: var(--secondary-grey-color);
  font-family: "Inter, sans-serif";
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  padding: 8px;
  border-radius: 50%;
  margin-top: 8px;
  cursor: pointer;
  /* margin-left: 4px;
    margin-right: 4px; */
}

.dashboard-date-picker .react-calendar__tile:disabled {
  background: var(--navy-blue-color);
  border-radius: 50%;
}

.dashboard-date-picker .react-calendar__tile--rangeStart,
.dashboard-date-picker .react-calendar__tile--rangeEnd,
.dashboard-date-picker .react-calendar__tile--hasActive,
.dashboard-date-picker .react-calendar__tile:enabled:hover,
.dashboard-date-picker .react-calendar__tile:enabled:focus {
  /* background: var(--secondary-neon-color) !important; */
  background-color: #ff5733 !important;
  border-radius: 50%;
  color: white !important;
}

.dashboard-date-picker .react-calendar__tile--active,
.dashboard-date-picker
  .react-calendar--selectRange
  .react-calendar__tile--hover {
  background: linear-gradient(
    100deg,
    #ff5733 0.25%,
    #fd4c26 46.18%,
    #fa8d76 97.15%
  );
  color: white !important;
  border-radius: 50% !important;
}

.dashboard-date-picker .react-calendar__tile--now {
  /* background: transparent; */
  border-radius: 50%;
}
.analytics-calendar-wrapper .calendar-custom-wrapper {
  /* top: 40px !important; */
}

.e-popup.e-popup-open, .e-schedule-dialog {
  z-index: 2147483647 !important;
}

.ql-tooltip {
  left: 0 !important;
}
.MuiAutocomplete-popper {
  z-index: 9999999 !important;
}
